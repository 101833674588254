import { render, staticRenderFns } from "./QuickLinks.vue?vue&type=template&id=6b7362ba&"
import script from "./QuickLinks.vue?vue&type=script&lang=js&"
export * from "./QuickLinks.vue?vue&type=script&lang=js&"
import style0 from "./QuickLinks.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusTitle: require('/~/src/modules/opus/components/Title.vue').default,ContentfulVideo: require('/~/src/modules/contentful/components/ContentfulVideo.vue').default,OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default,OpusLink: require('/~/src/modules/opus/components/Link.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default,Slider: require('/~/src/components/Slider.vue').default})
